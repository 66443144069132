import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Agradecimento from '../agradecimento/Agradecimento';
import './formulario.css'

const Formulario = () => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [botaoAtivo, setBotaoAtivo] = useState(false);
    const [perguntaSecreta1, setPerguntaSecreta1] = useState("")
    const [perguntaSecreta2, setPerguntaSecreta2] = useState("")
    const [perguntaSecreta3, setPerguntaSecreta3] = useState("")
    const [submit, setSubmit] = useState(false)
        const isEmailValid = (email) => {
            // Regular expression for email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        // UseEffect to check if the email is valid
        useEffect(() => {
            const isEmail = isEmailValid(email);
            setBotaoAtivo(nome.trim() !== '' && isEmail && perguntaSecreta1.trim().toLowerCase() === 'planejar' && perguntaSecreta2.trim().toLowerCase() === 'organizar' && perguntaSecreta3.trim().toLowerCase() === 'iniciar');
    
            
        }, [nome, email, perguntaSecreta1, perguntaSecreta2, perguntaSecreta3]);

    const handleGerarPDF = async () => {
        
        try {
            const response =  await axios.post(
                'https://backendformularioemissor-402ee8aef43a.herokuapp.com/gerarPdf',
                { nome, email },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if(response){
                console.log(response.data)
                setSubmit(true)
            }
           
        } catch (error) {
            console.error('Erro ao enviar requisição:', error);
        }
    };

    return (
        <>
            {submit ? (
                <Agradecimento />
            ) : (
                <div className='div-princ'>
            <form className="form">
                <h1>Dados para certificado.</h1>
                <div className='inputs'>
                    <label className="label">
                        Nome:
                        <input type="text" className="input" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </label>
                    <label className="label">
                        Email:
                        <input type="text" className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label className="label">
                        Palavra chave 1:
                        <input type="text" className="input" value={perguntaSecreta1} onChange={(e) => setPerguntaSecreta1(String(e.target.value))} />
                    </label>
                    <label className="label">
                    Palavra chave 2:
                        <input type="text" className="input" value={perguntaSecreta2} onChange={(e) => setPerguntaSecreta2(String(e.target.value))} />
                    </label>
                    <label className="label">
                    Palavra chave 3:
                        <input type="text" className="input" value={perguntaSecreta3} onChange={(e) => setPerguntaSecreta3(String(e.target.value))} />
                    </label>
                </div>
                {!botaoAtivo ? <p className='alteracao-ultima'>Preencha todos os campos<br></br> para gerar seu certificado</p> : <button className="button" onClick={(e) => {handleGerarPDF(); e.preventDefault()}} onSubmit={(e) => {e.preventDefault()}} disabled={!botaoAtivo}>
                    Emitir certificado
                </button>}
                
            </form>
        </div>
            )}
        </>
    );
};

export default Formulario;
