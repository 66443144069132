import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Formulario from './components/formulario/Formulario';

import Agradecimento from './components/agradecimento/Agradecimento';;

const App = () => {
  return (
    <Router>
      <Switch>
      <Route path="/agradecimento" render={() => <Agradecimento />} />
        <Route path="/" component={Formulario} />
      </Switch>
    </Router>
  );
};

export default App;